import {
    Pagination,
    Sorting,
    WebshopCustomersPage,
    WebshopCustomerDetails,
    WebshopCustomer,
    Webshop,
    Locale,
    AnonymizeWebshopCustomerPayload,
    UpdateWebshopCustomerPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopCustomerEndpoint extends Endpoint {
    public async GetWebshopCustomers(
        webshopId: Webshop['webshopId'],
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            locale?: Locale;
        },
        search?: string,
    ): Promise<WebshopCustomersPage> {
        const response = await this.client.get({
            url: `/customer/overview/webshop/${webshopId}`,
            params: {
                pagination,
                sorting,
                filters,
                search,
            },
        });
        this.guardResponseSchema('#/definitions/WebshopCustomersPage', response);
        return response;
    }
    public async GetWebshopCustomerDetails(customerId: WebshopCustomer['customerId']): Promise<WebshopCustomerDetails> {
        const response = await this.client.get({
            url: `/customer/details/${customerId}`,
        });
        this.guardResponseSchema('#/definitions/WebshopCustomerDetails', response);
        return response;
    }
    public GetWebshopCustomersExportURL(webshopId: Webshop['webshopId']): string {
        return this.client.buildApiURL(`/customer/overview/webshop/${webshopId}/export`, true);
    }

    public async UpdateCustomer(payload: UpdateWebshopCustomerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCustomer',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Customer\\UpdateCustomer\\UpdateCustomer',
                payload,
            },
        });
    }

    public async AnonymizeCustomer(payload: AnonymizeWebshopCustomerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AnonymizeCustomer',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Customer\\AnonymizeCustomer\\AnonymizeCustomer',
                payload,
            },
        });
    }
}
