import { LocalConfig } from './types';


const localConfig: LocalConfig = {
    apiBaseUrl: 'https://platform-api.stag.lochting.com',
    deviceSignalingServerHost: 'signaling.stag.lochting.com:443',
    logging: {
        enabled: true,
        dataDog: {
            clientToken: 'pubf5c3d03c3367e69848be2a32c860c63c',
            env: 'staging'
        }
    },
    featureFlags: {
        enableBacksideStocking: true
    },
    vendingMachinePrivacyPolicyPageUrl: 'https://platform.stag.lochting.com/vending-machine-privacy-policy',

}

export default localConfig
